<template>
  <v-main>
    <v-container class="py-12 bl_classPast">
      <v-row class="mb-12">
        <v-col>
          <div class="">
            <h2>過去に開催したデジタルお絵かきWORKSHOP</h2>
          </div>
        </v-col>
      </v-row>
      <v-row class="my-12">
        <transition>
          <v-col cols="12" class="bl_wsWrapper">
            <class-card
            classNen="2023"
            classTsuki="6"
            classHi="30"
            classYoubi="Fri"
            classTime="11:00-13:00"
            statusText="開催済"
            imgUrl="230630_tsukuru_vector-2.png"
            reserveUrl="https://dow23630.peatix.com/"
            title="プロになる前に知っておきたいベクターブラシの使い方"
            location="tsukuru"
            :tagList="['初心者向け', 'ベクター', 'キャラクター']"
            >
              <p>ベクターブラシと仲良くなろう！ 印刷でもwebでも大活躍、商業的にイラストを扱うなら知っておきたいベクター形式での制作を初心者さん向けに解説します。ラスター形式との違い、ベクターデータの用途、注意点等座学が多めです。Adobe Frescoのベクターブラシで実際にイラストを作成します。特にillustratorと合わせて使用する方におすすめです。</p>
            </class-card> 

            <class-card
            classNen="2023"
            classTsuki="5"
            classHi="26"
            classYoubi="Fri"
            classTime="18:30-21:00"
            statusText="開催済"
            imgUrl="230526_ten_dessin-3.png"
            reserveUrl="https://peatix.com/event/3577605/view?fbclid=IwAR2sd9eMosM15ZXSLH0IoyAoHQflI3gH4JSCEzTXmb8KGKm6v7BRF-7a6Jc"
            title="[TEN's WORKSHOP]デジタルで描く光と影のデッサン"
            location="ten"
            :tagList="['初心者向け', 'デッサン', '静物画']"
            >
              <p>グレーの下地に光＝白と影＝黒でデッサンする<strong>キアロスクーロ素描</strong>で、明暗の捉え方を練習しましょう。初心者さん向けにシンプルなモチーフをご用意します。申し込みのご連絡はSTUDIO TEN様まで！</p>
            </class-card> 

            <class-card 
            classNen="2023"
            classTsuki="5"
            classHi="24"
            classYoubi="Wed"
            classTime="13:00-15:45"
            statusText="開催済"
            imgUrl="230524_ishikura_ws-4.png"
            reserveUrl="https://peatix.com/event/3573479/view"
            title="Adobe Frescoで水彩風のお花を描こう"
            location="ishikura"
            :tagList="['初心者向け', '水彩風', '植物画']"
            >
              <p>人気の水彩風イラストをAdobe Frescoでささっと描いてみましょう。Adobe Frescoが得意とするAIを使ったにじみや色の重ねを楽しめます。</p>
            </class-card>

            <class-card 
            classNen="2023"
            classTsuki="3"
            classHi="5"
            classYoubi="Sun"
            classTime="13:00-16:00"
            statusText="開催済"
            imgUrl="230305_ws_studioten_1_mini.png"
            title="Adobe Frescoで始めるデジタルお絵描きワークショップ"
            location="ten"
            :tagList="['初心者向け', 'ツールの使い方', '風景画']"
            :disabled="true"
            >
              <p>木のある風景を描きながら基本操作を覚えます。よく使うツールを一通り説明するので、初学者さんや基本を復習したい方におすすめです。</p>
            </class-card>
          </v-col>
        </transition>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import ClassCard from '../components/molecules/ClassCard.vue'

export default {
  components: { ClassCard },
  name: 'ClassIndex',
  data(){
    return{
      //isActive: 0,
      dialog: false, //キャンペーンのモーダル開閉
    }
  },
  methods:{
    // ActiveDetail(value) {
    //   if(this.isActive == value){
    //     this.isActive = 0
    //   }else{
    //     this.isActive = value;
    //   }
    // },
  }
};
</script>
<style scoped>
.theme--light.v-card > .v-card__text, .theme--light.v-card .v-card__subtitle {
    color: rgba(0, 0, 0, 0.9);
}

.bl_wsWrapper{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 16px;
  row-gap: 32px;
}
.bl_wsWrapper >>> .bl_card.bl_card__ws.isActive{
  box-shadow: 0px 1px 1px -2px rgba(0, 0, 0, 0.25), 0px 5px 5px 2px rgba(0, 0, 0, 0.15), 0px 4px 13px 2px rgba(0, 0, 0, 0.13);
}
.bl_classPast >>> .bl_card.bl_card__ws .bl_card__ws_detail{
    display: none;
}

@media screen and (max-width: 1280px) {
  .bl_wsWrapper{
    grid-template-columns: 1fr;
  }

  .bl_wsWrapper >>> .bl_card.bl_card__ws.isActive{
    grid-column: 1 / -1;
  }
}

@media screen and (max-width: 700px) {
  .bl_wsWrapper{
    grid-template-columns: 1fr;
  }
}
</style>
